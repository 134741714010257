/* styles.css */
.react-calendar{
    border: none;
    padding: 20px;
    width: 100%;
  }
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button, .react-calendar__navigation__arrow.react-calendar__navigation__next2-button{
    font-size: 36px;
    color: #0E7A70;
}
.react-calendar__navigation__arrow.react-calendar__navigation__prev-button, .react-calendar__navigation__arrow.react-calendar__navigation__next-button{
    font-size: 25px;
    color: #0E7A70;
}
.react-calendar__navigation__label{
    font-size: 17px;
    font-weight: 600;
    color: #0E7A70;
}
.react-calendar__month-view__weekdays, .react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth{
    font-size: 14px;
    color: #696A6C;
}
.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--weekend{
    font-size: 14px;
    color: #0E7A70;
}
.react-calendar__tile--active{
    background-color: #0E7A70;
    font-size: 14px;
    border-radius: 5%;
}

.react-calendar__tile.react-calendar__month-view__days__day p{
    color: red;
}
.calendar-area h3 {
    font-size: 20px;
    font-weight: 600;
    color: white;
    background-color: #0E7A70;
    border-radius: 5px;
    padding: 17px 15px;
}